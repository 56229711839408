import {
  ClientApp,
  ClientAppProps,
  initBugsnag,
  initPerformanceAnalytics,
} from '@stitch-fix/sf-next';
import { ThemeProvider } from '@stitch-fix/mode-react';
import { AnimatePresence } from 'framer-motion';
import { initDatadogLogger } from '../utils/loggers/browser';
import { NavigationProvider } from '../components/OnboardingVision/NavigationProvider';
import { FlowProvider } from '../features/shippingAndPayments/components/FlowProvider';
import '../styles/global.scss';

// ⚠️ ⚠️ ⚠️ Before making any changes to this file, test your proposed changes in production
// via the POC application on both SSR and SSG pages

// Initialize Datadog logger
initDatadogLogger();

// Initialize Bugsnag error monitoring for your project.
// For configuration options, including how to debug locally, view guide at:
// https://github.com/stitchfix/sf-next/blob/beta/docs/bugsnag.md
initBugsnag();

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  // require is synchronous, which allows MSW to be initialized before the app renders,
  // see: https://github.com/mswjs/msw/discussions/1236
  // eslint-disable-next-line global-require
  require('../mocks/msw');
}

// Initialize performance analytics for your project.
// For configuration options, including how to debug locally or increase
// the sample rate, view guide at:
initPerformanceAnalytics({
  sampleRate: 100,
});

// Report on next.js metrics and mark `app_rendered`.
// AUTOGENERATED BY SF-NEXT TO SUPPORT TRACKING PERFORMANCE ANALYTICS. DO NOT
// REMOVE.
export { reportWebVitals } from '@stitch-fix/sf-next';

const App = (props: ClientAppProps) => {
  if (
    props.router.pathname.startsWith('/quiz/') ||
    props.router.pathname.startsWith('/signup')
  ) {
    return (
      <NavigationProvider>
        <AnimatePresence mode="wait" initial={false}>
          <ClientApp {...props} key={props.router.asPath} />
        </AnimatePresence>
      </NavigationProvider>
    );
  }

  if (props.router.pathname.startsWith('/get-your-fix/')) {
    return (
      <NavigationProvider>
        <ThemeProvider theme="brand-2024-full">
          <FlowProvider>
            <ClientApp {...props} />
          </FlowProvider>
        </ThemeProvider>
      </NavigationProvider>
    );
  }

  if (
    props.router.pathname.startsWith('/wrap-up') ||
    props.router.pathname.startsWith('/stylefile') ||
    props.router.pathname.startsWith('/profile-note') ||
    props.router.pathname.startsWith('/onboarding/add-a-kid') ||
    props.router.pathname.startsWith('/onboarding/style-shuffle')
  ) {
    return (
      <NavigationProvider>
        <ThemeProvider theme="brand-2024-full">
          <AnimatePresence mode="wait" initial={false}>
            <ClientApp {...props} key={props.router.asPath} />
          </AnimatePresence>
        </ThemeProvider>
      </NavigationProvider>
    );
  }

  if (props.router.pathname.startsWith('/client/stylefile/quiz')) {
    return (
      <NavigationProvider>
        <ClientApp {...props} />
      </NavigationProvider>
    );
  }

  return <ClientApp {...props} />;
};

export default App;
