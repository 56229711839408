import { G } from '@mobily/ts-belt';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useClientAuth } from '@stitch-fix/sf-next';
import { defaultFlow, FlowId, flows, Step } from './flows';
import { getFirstAvailableStepIndex } from './utils';

// This hook is to ensure that the flow and step in the url are valid.
// If they are not, we redirect to the default flow and first step
export const useFlowStepQueryGuard = () => {
  const router = useRouter();
  const { client } = useClientAuth();
  const { flow } = router.query;
  let flowId: FlowId | undefined;
  let stepId: string | undefined;
  let currentStep: Step | undefined;
  let currentStepIndex: number | undefined;
  let initialStepIndex: number | undefined;

  useEffect(() => {
    if (router.isReady === false) return;

    if (!client) return;

    // If the flow is not an array or the first element of the array is not in
    // the flows object, that means the url does not have the data we
    // need to render a flow and its steps. Therefore we should redirect to the
    // default flow and first available step
    if (!G.isArray(flow) || !(flow[0] in flows)) {
      router.replace({
        pathname: '/get-your-fix/[flow]/[step]',
        query: {
          flow: defaultFlow,
          step: flows[defaultFlow][
            getFirstAvailableStepIndex({
              businessLine: client.businessLine,
              flow: defaultFlow,
            })
          ].step,
        },
      });

      return;
    }

    const [_flowId, _stepId] = flow;

    // if the flowId is part of the flows object, but the stepId is not in the
    // flow, redirect to the first available step of the flow
    if (
      _flowId in flows &&
      (!G.isString(_stepId) ||
        !flows[_flowId as FlowId].find(f => f.step === _stepId))
    ) {
      router.replace({
        pathname: '/get-your-fix/[flow]/[step]',
        query: {
          flow: _flowId,
          step: flows[_flowId as FlowId][
            getFirstAvailableStepIndex({
              businessLine: client.businessLine,
              flow: _flowId as FlowId,
            })
          ].step,
        },
      });
    }
  }, [flow, router, client]);

  // If the flow is an array and the first element of the array is in the flows
  // object, we can safely assume that the flow and step are valid
  if (G.isArray(flow) && flow[0] in flows) {
    flowId = flow[0] as FlowId;

    if (G.isString(flow[1]) && flows[flowId].find(f => f.step === flow[1])) {
      stepId = flow[1];
      currentStepIndex = flows[flowId].findIndex(f => f.step === stepId);
      currentStep = flows[flowId][currentStepIndex];
      initialStepIndex = getFirstAvailableStepIndex({
        businessLine: client?.businessLine ?? null,
        flow: flowId,
      });
    }
  }

  return {
    flow: flowId,
    currentStep,
    currentStepIndex,
    initialStepIndex,
  };
};
